<template>
  <div class="detalle-cierre mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Cierre inventario -
            <span v-if="$route.params.stock == '1'"> Venta </span>
            <span v-else> Consumo</span>
            - {{ moment($route.params.date).format("MMMM/YYYY") }}
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              dark
              @click="$router.push('/cierreInventarios')"
              >Lista de cierres</v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :headers="h_stock"
                  :footer-props="{ itemsPerPageText: 'Stock' }"
                  :items="cierres"
                  :search="search"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Stock</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field
                        class="mt-4"
                        outlined
                        rounded
                        dense
                        append-icon="mdi-magnify"
                        label="Buscar"
                        v-model="search"
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-alert colored-border color="primary" border="top">
                  <v-sheet>
                    <v-data-table
                      :headers="h_compras"
                      :footer-props="{ itemsPerPageText: 'Compras' }"
                      :items="entradas"
                      :search="search_compra"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Compras</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-text-field
                            class="mt-4"
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            v-model="search_compra"
                          ></v-text-field>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.precio="{ item }">
                        {{ item.precio | currency }}
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ item.total | currency }}
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-alert>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-alert colored-border color="success" border="top">
                  <v-sheet>
                    <v-data-table
                      :headers="h_salidas"
                      :search="search_salidas"
                      :footer-props="{ itemsPerPageText: 'Ventas' }"
                      :items="ventas"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Ventas</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-text-field
                            class="mt-4"
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            v-model="search_salidas"
                          ></v-text-field>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.precio="{ item }">
                        {{ item.precio | currency }}
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ item.total | currency }}
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "detalleCierre",
  data: () => ({
    search: "",
    search_compra: "",
    search_salidas: "",
    cierres: [],
    productos: [],
    h_stock: [
      {
        text: "Codigo",
        value: "codigo",
        align: "start",
        sortable: false,
      },
      {
        text: "Producto",
        value: "producto",
      },
      {
        text: "Stock Inicial",
        value: "inicial_stock",
      },
      {
        text: "Entradas",
        value: "entradas",
      },
      {
        text: "Salidas",
        value: "salidas",
      },
      {
        text: "Stock Final",
        value: "stock",
      },
    ],
    h_compras: [
      {
        text: "Codigo",
        value: "codigo",
        align: "start",
        sortable: false,
      },
      {
        text: "Producto",
        value: "producto",
      },
      {
        text: "Entradas",
        value: "entradas",
      },
      {
        text: "Precio",
        value: "precio",
      },
      {
        text: "Total",
        value: "total",
      },
    ],
    h_salidas: [
      {
        text: "Codigo",
        value: "codigo",
        align: "start",
        sortable: false,
      },
      {
        text: "Producto",
        value: "producto",
      },
      {
        text: "Salidas",
        value: "salidas",
      },
      {
        text: "Precio",
        value: "precio",
      },
      {
        text: "Total",
        value: "total",
      },
    ],
  }),
  methods: {
    loadDetalleCierre() {
      const body = {
        route: "/detalle_cierre",
        params: {
          stock: parseInt(this.$route.params.stock),
          date: this.$route.params.date,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.cierres = response.data.data;
        }
      });
    },
    loadStock() {
      const body = {
        route: "/stock_productos",
        params: {
          stock: parseInt(this.$route.params.stock),
          date: {
            inicio: this.$route.params.date,
            fin: moment(this.$route.params.date)
              .endOf("month")
              .format("YYYY-MM-DD"),
          },
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.productos = response.data.data;
        }
      });
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadDetalleCierre();
    this.loadStock();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    entradas() {
      return this.productos.map((p) => {
        const entradas = p.entradas + p.m_entradas;
        const total = p.precio_compra + p.compra * p.m_entradas;
        const precio = isNaN(total / entradas) ? p.compra : total / entradas;
        return {
          codigo: p.codigo,
          producto: p.producto,
          entradas,
          precio,
          total,
        };
      });
    },
    ventas() {
      return this.productos.map((p) => {
        const salidas = p.salidas + p.m_salidas;
        const total = p.precio_venta;
        const precio = isNaN(total / salidas) ? p.venta : total / salidas;
        return {
          codigo: p.codigo,
          producto: p.producto,
          salidas,
          precio,
          total,
        };
      });
    },
  },
};
</script>
